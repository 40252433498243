import { USER_TYPE } from "utils/constants";
import { QUESTION_TYPES } from "components/Quiz/utils";
import { RecordItem } from "types/common";

export type PAGE_QUIZ_CTA_BUTTON_INFO = {
  ctaText: string;
  slug: string;
};

export type AddressAnswer = {
  latitude?: number;
  longitude?: number;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
};

export type ContactAnswer = {
  firstName: string;
  lastName?: string;
  email?: string;
  phone?: string;
  company?: string;
  marketingSubscriber?: boolean;
  finalPhoneNumber?: string;
};

export enum ImageType {
  Portfolio = "portfolio",
  Inspiration = "inspiration"
}

export type ImagesAnswer = Record<ImageType, string[]>;

export enum QuizQuestionDisplayType {
  Blocks = "blocks",
  Grid = "grid"
}

export enum QuizQuestionInputType {
  LongText = "longText",
  Slider = "slider"
}

export type QuizQuestionInputProps = {
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number | string;
  label?: string;
  placeholder?: string;
  valuePrefix?: string;
  formatAsCurrency?: boolean;
};

export enum QuizQuestionConditionOperator {
  Equals = "equals",
  In = "in"
}

export type QuizQuestionCondition = {
  operator: QuizQuestionConditionOperator;
  dependsOn: string;
  value: string | string[];
};

export type QuizQuestionVisibleFields = {
  name?: boolean;
  email?: boolean;
  phone?: boolean;
  company?: boolean;
};
export type QuizQuestion = {
  id: string;
  type: keyof typeof QUESTION_TYPES;
  displayType?: QuizQuestionDisplayType;
  gridColumns?: number;
  mobileGridColumns?: number;
  inputType?: QuizQuestionInputType;
  inputProps?: QuizQuestionInputProps;
  isAutoNextSlide?: boolean;
  answer?: QuizQuestionAnswer;
  allowMultiAnswers?: boolean;
  questionFormat?: string;
  extraInfo?: { enabled: boolean; icon: RecordItem; text: string };
  title: string;
  subtitle?: string;
  images?: RecordItem[];
  skipText?: string;
  showSkip?: boolean;
  buttonLabel?: string; // used for next button in QuizFooter
  webhookURL?: string;
  isRequired?: boolean;
  redirectLink?: {
    slug?: string;
  };
  skipLink?: {
    slug?: string;
  };
  additionalAddressInfoFields: {
    enabled?: boolean;
    title?: string;
    subtitle?: string;
    fields?: QuizAddressAdditionalInfoField[];
  };
  userType?: USER_TYPE;
  options?: Array<{
    id: string;
    image?: RecordItem;
    primaryText: string;
    secondaryText?: string;
    metaTags: Array<{
      type: string;
      name: string;
      id: string;
      value: string;
    }>;
  }>;
  conditionalDisplay?: boolean;
  conditionalLogic?: QuizQuestionCondition[];
  steps?: string[];
  visibleFields?: QuizQuestionVisibleFields;
  skipTextClicked?: boolean;
  enablePhoneVerification?: boolean;
  enableSliderLoader?: boolean;
};

export type InputAnswer = string | number;

export type QuizQuestionAnswer =
  | {
      id: string;
      path?: string;

      title?: string;
      primaryText?: string;
      imagesAnswer?: string[];
    }
  | ImagesAnswer
  | AddressAnswer
  | ContactAnswer
  | InputAnswer
  | string[];

export type QuizNextClickProps = {
  questionId?: string;
  answer?: QuizQuestionAnswer;
  houzzUserDetails?: RecordItem;
};

export enum ProgressBarAppearance {
  PRIMARY = "primary",
  ACCENT = "accent"
}

export type QuizAddressAdditionalInfoField = {
  id: string;
  title?: string;
  subtitle?: string;
  value?: string;
};

export type QuizAddressAdditionalFieldsResponse = {
  data?: {
    fields?: QuizAddressAdditionalInfoField[];
    image?: string;
  } | null;
  error?: string | null;
};
